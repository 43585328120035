import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PdfLink from "./Components/MyPDF";
import Inputform from './Components/InputForm';
import SimpleLogin from './Components/SimpleLogin';


const App = () => {
  const [showLabel, setShowLabel] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (Object.keys(formData).length) {
      setShowLabel(true);
      setShowModal(true);
    }
  }, [formData]);

  const closeModal = () => {
    setShowModal(false);
  }

  return (
    <div className="container">
      {!loggedIn && (
        <div className="block">
          <SimpleLogin
            setLoggedIn={setLoggedIn}
          />
        </div>
      )}

      {loggedIn && (
      <div>
        <div className="block">
          <Inputform
            setFormData={setFormData}
          />
        </div>


        {showLabel && (
          <div className={classnames('modal', {
            'is-active': showModal
          })}>
            <div className="modal-background" onClick={closeModal}></div>
            <div className="modal-content">
              <header className="modal-card-head">
                <p className="modal-card-title">Generating PDF</p>
                <button className="delete" aria-label="close" onClick={closeModal}></button>
              </header>
              <section className="modal-card-body">
                <p className="block">PDF Download is ready. Click the button below to download and save.</p>
                <PdfLink
                  data={formData}
                />
              </section>
            </div>
          </div>

        )}
        </div>
      )}
    </div>
  );
};

export default App;
