import React from 'react';
import { Formik } from 'formik';

const InputError = ({error}) => (
  <p className="help is-danger">{error}</p>
);

const Inputform = ({setFormData}) => {
  return (
    <div>
      <h1 className="title">Be Handmade Soaps</h1>
      <h2 className="subtitle">PDF Label Generator</h2>
      <Formik
        initialValues={{ inputSoapName: '', inputSoapIngredients: '', inputSoapDescription: '', inputLabelCount: '1' }}
        validate={values => {
          const errors = {};
          if (!values.inputSoapName) {
            errors.inputSoapName = 'Required';
          }
          if (!values.inputSoapIngredients) {
            errors.inputSoapIngredients = 'Required';
          }
          if (!values.inputSoapDescription) {
            errors.inputSoapDescription = 'Required';
          }

          if (values.inputSoapName.length > 25) {
            errors.inputSoapName = 'This should be less than 25 characters';
          }

          if (values.inputSoapIngredients.length > 200) {
            errors.inputSoapIngredients = 'This should be less than 200 characters';
          }

          if (values.inputSoapDescription.length > 170) {
            errors.inputSoapDescription = 'This should be less than 170 characters';
          }

          return errors;
        }}

        onSubmit={(values, { setSubmitting }) => {
          setFormData(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label" htmlFor="inputLabelCount">Labels per page (1 - 5)</label>
              <div className="control">
                <div className="select">
                  <select
                    name="inputLabelCount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
              {errors.inputLabelCount && touched.inputLabelCount && (
                <InputError error={errors.inputLabelCount} />
              )}
            </div>

            <div className="field">
              <label className="label" htmlFor="inputSoapName">Name of Soap</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="inputSoapName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.inputSoapName}
                />
              </div>
              <p className="help">25 characters or less.</p>
              {errors.inputSoapName && touched.inputSoapName && (
                <InputError error={errors.inputSoapName} />
              )}
            </div>

            <div className="field">
              <label className="label" htmlFor="inputSoapIngredients">Ingredients</label>
              <div className="control">
                <textarea
                  className="textarea"
                  name="inputSoapIngredients"
                  placeholder="Enter Soap Ingredients. This should be around 200 characters to look best."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.inputSoapIngredients}
                />
              </div>
              <p className="help">This should be around 200 characters to look best.</p>
              {errors.inputSoapIngredients && touched.inputSoapIngredients && (
                <InputError error={errors.inputSoapIngredients} />
              )}
            </div>

            <div className="field">
              <label className="label" htmlFor="inputSoapDescription">Soap Description</label>
              <div className="control">
                <textarea
                  className="textarea"
                  name="inputSoapDescription"
                  placeholder="Enter Soap Description. This should be around 170 characters to look best."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.inputSoapDescription}
                />
              </div>
              <p className="help">This should be around 170 characters to look best.</p>
              {errors.inputSoapDescription && touched.inputSoapDescription && (
                <InputError error={errors.inputSoapDescription} />
              )}
            </div>

            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="button is-link"
                >
                  {isSubmitting ? "Generating..." : "Generate Label"}
                </button>
              </div>
            </div>
          </form>
        )}
     </Formik>
   </div>
  );
}

export default Inputform;