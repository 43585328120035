import React, { useMemo } from 'react';
import { PDFDownloadLink, Page, Text, View, Font, Image, Document, StyleSheet } from '@react-pdf/renderer';

// Assets.
import logoMark from '../Assets/logo_mark.png';
import topography from '../Assets/Topography.png';
import instagram from '../Assets/instagramIcon-2.png';

// PDF Components.
import LabelFront from './PDFComponents/LabelFront';

import FieldworkHumRegular from '../Fonts/print/Fieldwork-HumRegular.ttf';
import FieldworkHumBold from '../Fonts/print/Fieldwork-HumBold.ttf';

Font.register({
  family: 'FieldworkHum',
  fonts: [
    {
      src: FieldworkHumRegular,
    },
    {
      src: FieldworkHumBold,
      fontWeight: 'bold',
    },
  ]
});

// Create styles.
const styles = StyleSheet.create({
  // Create styles
  viewer: {
    width: '100%',
    height: '100vh',
  },
  page: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    marginLeft: '40',
    marginTop: '30',
  },
  label: {
    width: '7.5in',
    height: '1.5in',
    backgroundColor: '#F39B82',
    flexDirection: 'row',
    marginBottom: '30'
  },
  logo: {
    width: '1.75in',
    height: "auto",
    marginTop: 12,
    marginBottom: 2,
    marginHorizontal: 'auto',
  },
  topography: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  frontBack: {
    width: '2.75in',
    height: '1.5in',
  },
  back: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  side1: {
    width: '1in',
    height: '1.5in',
    backgroundColor: '#4D4D4D',
  },
  side2: {
    width: '1in',
    height: '1.5in',
    backgroundColor: '#4D4D4D',
  },
  separator: {
    backgroundColor: '#FFFFFF',
    width: '0.5in',
    height: '.03in',
    marginVertical: 5,
    marginHorizontal: 'auto',
  },
  sideContent: {
    transform: 'rotate(-90deg), translateX(6), translateY(10)',
    transformOrigin: 'center bottom',
    width: '1.5in',
    fontSize: '6pt',
    fontFamily: 'FieldworkHum',
    color: '#FFFFFF',
    // backgroundColor: '#FF0000', // debugging placement
  },
  logoMark: {
    width: '40',
    height: '40',
    marginHorizontal: 'auto',
  },
  social: {
    flexDirection: 'row',
    marginHorizontal: 'auto',
  },
  ingredientsText: {
    textAlign: 'center',
    fontFamily: 'FieldworkHum',
    fontSize: '5pt',
    color: '#FFFFFF',
    paddingTop: 15,
    paddingHorizontal: 10,
    transform: 'rotate(90deg), translateY(-72), translateX(-1)',
    transformOrigin: 'left top',
    // backgroundColor: '#FF0000', // debugging placement
    width: '1.5in',
  },
  soapNameText: {
    textAlign: 'center',
    fontFamily: 'FieldworkHum',
    fontWeight: 'bold',
    fontSize: '10pt',
    letterSpacing: '1pt',
    color: '#FFFFFF',
  },
  aboutText: {
    textAlign: 'center',
    fontFamily: 'FieldworkHum',
    fontSize: '7pt',
    color: '#FFFFFF',
  },
  instagramIcon: {
    width: '0.1in',
    height: '0.1in',
    top: '1',
    marginRight: '3'
  }
});

const aboutUs = "We are a small family operated company based out of Kannapolis, NC. We are dedicated to quality and fantastical things";

const website = "BEHANDMADESOAPS.COM";
const social = "BEHANDMADESOAPS";

const Label = ({data}) => {
  const {inputSoapName, inputSoapIngredients, inputSoapDescription} = data;
  return (
    <View style={styles.label}>
      {/* SIDE: Ingredients List */}
      {/* ================================= */}
      <View style={styles.side2}>
        <View style={styles.topography}>
          <Image src={topography} />
        </View>
        <Text style={styles.ingredientsText}>
          {inputSoapIngredients}
        </Text>
      </View>


      {/* Front of Label */}
      {/* ================================= */}
      <LabelFront styles={styles} inputSoapName={inputSoapName} />


      {/* SIDE: Where to find us */}
      {/* ================================= */}
      <View style={styles.side1}>
        <View style={styles.topography}>
          <Image src={topography} />
        </View>
        <View style={styles.sideContent}>
          <Image src={logoMark} style={styles.logoMark}/>
          <Text style={{textAlign: 'center', marginBottom: '3'}}>{website}</Text>

          <View style={styles.social}>
            <Image src={instagram} style={styles.instagramIcon} />
            <Text>{social}</Text>
          </View>
        </View>
      </View>

      {/* Back of label */}
      {/* ================================= */}
      <View style={styles.frontBack}>
        <View style={styles.topography}>
          <Image src={topography} />
        </View>

        <View style={styles.back}>
          <Text style={styles.aboutText}>
            {inputSoapDescription}
          </Text>

          <View style={styles.separator}>
          </View>

          <Text style={styles.aboutText}>
            {aboutUs}
          </Text>
        </View>
      </View>
    </View>
  );
};

const MyPDF = ({data}) => {
  const {inputLabelCount} = data;
  const labelCount = Array.from({length: inputLabelCount}, (x, i) => i);

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        {labelCount.map((_, key) => (
          <Label data={data} key={key} />
        ))}
      </Page>
    </Document>
  );
};

const PdfLink = ({data}) => {
  const DisabledPDFLink = () => (
    <button className="button is-link" title="PDF is being generated" disabled>Generating PDF...</button>
  );
  const DownloadPDFLink = () => (
    <button className="button is-link" title="PDF is ready for download" >Download Label PDF</button>
  );

  return useMemo(
    () => (
      <PDFDownloadLink
        document={<MyPDF data={data} />}
        fileName="beHandmadeSoapsLabel.pdf"
      >
        {({ loading }) => (
          loading ? <DisabledPDFLink /> : <DownloadPDFLink />
        )}

      </PDFDownloadLink>
    ),
    [data],
  )
}

export default PdfLink;
