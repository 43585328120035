import { Text, View, Image } from '@react-pdf/renderer';

import topography from '../../Assets/Topography.png';
import logo from '../../Assets/logo.png';

const LabelFront = ({styles, inputSoapName}) => {
  return (
    <View style={styles.frontBack}>
      <View style={styles.topography}>
        <Image src={topography} />
      </View>

      <Image src={logo} style={styles.logo} />

      {/* Rectangular separator */}
      <View style={styles.separator}>
      </View>

      <Text style={styles.soapNameText}>{inputSoapName}</Text>
    </View>
  );
}

export default LabelFront;
