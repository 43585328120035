import React from 'react';
import { Formik } from 'formik';

const InputError = ({error}) => (
  <p className="help is-danger">{error}</p>
);

const password = "rotor-repawn-stocks";
const username = "bfx";

const SimpleLogin = ({setLoggedIn}) => {
  return (
    <div>
      <h1 className="title">Be Handmade Soaps</h1>
      <h2 className="subtitle">Sign in to Generate Labels</h2>
      <Formik
        initialValues={{ username: '', password: '' }}
        validate={values => {
          const errors = {};
          if (!values.username) {
            errors.username = 'Required';
          }
          if (!values.password) {
            errors.password = 'Required';
          }

          if (password !== values.password && username !== values.username) {
            errors.username = 'Username does not match records';
            errors.password = 'Password does not match records';
          }

          return errors;
        }}

        onSubmit={(values, { setSubmitting }) => {
          setLoggedIn(true);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="field">
              <div className="control has-icons-left">
                <input
                  className="input"
                  placeholder="Username"
                  type="text"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </div>
              {errors.username && touched.username && (
                <InputError error={errors.username} />
              )}
            </div>

            <div className="field">
              <div className="control has-icons-left">
                <input
                  className="input"
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </div>
              {errors.password && touched.password && (
                <InputError error={errors.password} />
              )}
            </div>

            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="button is-link"
                >
                  {isSubmitting ? "Signing In" : "Sign In"}
                </button>
              </div>
            </div>
          </form>
        )}
     </Formik>
   </div>
  );
}

export default SimpleLogin;